@page  
{ 
    // size: auto;   /* auto is the initial value */ 
    // /* this affects the margin in the printer settings */ 
    margin: 18mm 10mm;  
} 

body.node-investigation_report{
    
    width: 100%;
    // width: calc(100% - 50mm);

    #header-search, #megamenu{
        display: none;
    }
    
    #header-branding{
        // max-width: 70%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 6em;
        margin-bottom: 15em;
        background-color: #FFF;
    
        .container {
            .row{
                width: 100% !important;
            }
            a.acronym{
                .header-acronym{
                    display: none !important;
                }
                .print-logo{
                    display: block !important;
                }
            }
        }
    }
    
    #header-branding > div > div > div:nth-child(1) > a{
        .web-logo{
            display: none !important;
        }
        .print-logo{
            display: block !important;
        }
    }
    
    main{
        .col-8{
            flex-basis: 100%;
            max-width: 100%;
            padding: 0;
        }
        .col-4{
            display: none;
        }
    }
    
    .investigation_report.card{
        // position: absolute;
        // bottom: 0;
        // left: 15%;
        page-break-after: always;
        max-width: 70%;
        margin: auto;
        .field--name-field-occurence-date
        , .field--name-field-event-location
        , .field--name-field-state
        , .field--name-field-investigation-phase
        , .field--name-field-investigation-status
        , .field--name-field-aviation-occurrence-type
        , .field--name-field-occurrence-category
        , .field--name-field-highest-injury-level
        {
            display: none;
        }
    }
    
    .title.page-title{
        margin-bottom: 8em;
        color: #1893d2;
        max-width: 70%;
        margin: 0 auto 12em;
        height: 200px;
        display: block;
    }

    .nav-tabs{
        border-bottom: 0;
        justify-content: flex-start;
        margin-top: 3rem;
        .nav-link{
            text-decoration: none;
            padding-left: 0;
            &:not(.active){
                display: none;
            }
            &.active{
                display: block;
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 1rem;
                flex: 0 0 auto;
                text-align: left;
            }
        }
    }

    
    .tabs:not(.primary){
        // page-break-before: always;
        display: block;
        // background-color: red;
    }
    .tabs .section{
        overflow: visible;
        margin: auto;
        width: 100%;

        img{
            max-width: 100%;
            height: auto;
        }

        // &.hidden{
        //     height: 500px !important;
        //     display: block !important;
        // }
    }

    .download-section.card, .to-top-container{
        display: none;
    }

    .field--name-field-safety-issues, .field--name-field-recommendations{
        display: none;
    }

    .field--name-field-vehicles{
        > .field__item{
            break-inside: avoid;
            margin-top: 3rem;
        }
    }

    .page-footer, footer{
        display: none;
    }
}